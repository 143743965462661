import { ConfigProvider } from 'antd';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './i18n';
import store from './store/store';

document.querySelectorAll<HTMLElement>('[data-component]').forEach(element => {
  const componentName = element.dataset.component;

  if (componentName) {
    import(`./components/${componentName}.tsx`).then(({ default: Component }) => {
      const props: Record<string, string> = Object.fromEntries(
        Object.entries(element.dataset)
          .filter(([key, value]) => key !== 'component' && typeof value === 'string')
          .map(([key, value]) => [key, value as string])
      );

      const root = createRoot(element);

      root.render(
        <Provider store={store}>
          <ConfigProvider
            theme={{
              token: {
                // Seed Token
                colorPrimary: '#219e3e',
                borderRadius: 5,
              },
            }}
          >
            <Component {...props} />
          </ConfigProvider>
        </Provider>
      );
    });
  }
});
